
    // @ is an alias to /src
    import Vue from "vue";
    import Component from "vue-class-component";
    import SignInForm from "@/components/SignInForm.vue";
    import { User } from "@/model/User";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";

    // https://vuejs.org/v2/guide/typescript.html

    @Component({
        components: { SignInForm }
    })
    export default class HomeSignIn extends Vue {

        mounted() {
            document.body.style.backgroundColor = "#EEE";
        }

        onAuthenticated() {
            const user = this.$store.state.signedInUser as User;
            if(user.isSupplier) this.$router.push("/supplierZone/");
            else if(user.isBuyer) this.$router.push("/buyerZone/supplierModuleMatrix/");
            else if(user.isCqmsOrSuperAdmin) this.$router.push("/supplierModuleMatrix/");
            else  this.$router.push("/messages/");
        }

        private async termsAndConditions() {
            const url = "api/terms/download";
            const blob: Blob = await apiClient.getNonJson(url);
            utils.downloadBlob(document, blob, "T&Cs")
        }
    }
